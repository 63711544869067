import React from "react";

const Info = () => {
  return (
    <div className="about__info grid">
      <div className="about__box1">
        <i class="bx bx-award about__icon"></i>

        <h3 className="about__title">Capital One</h3>
        <span className="about__title">2.5 years</span>
      </div>

      <div className="about__box2">
        <i class="bx bx-briefcase-alt about__icon"></i>

        <h3 className="about__title">Protenus</h3>
        <span className="about__title">2.5 years</span>
      </div>

      <div className="about__box3">
        <i class="bx bx-support about__icon"></i>

        <h3 className="about__title">Univ. of MD</h3>
        <span className="about__title">Computer Science</span>
      </div>
    </div>
  );
};

export default Info;
