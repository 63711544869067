import Work1 from "../../assets/hytracker.png";
import Work2 from "../../assets/hytracker.png";
import Work3 from "../../assets/hytracker.png";
import Work4 from "../../assets/hytracker.png";


export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "HyTracker",
    category: "web",
  },
  {
    id: 2,
    image: Work2,
    title: "Food App",
    category: "app",
  },
  {
    id: 3,
    image: Work3,
    title: "Turtle App",
    category: "design",
  },
  {
    id: 4,
    image: Work4,
    title: "Shopping App",
    category: "app",
  }
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "web",
  },
  {
    name: "app",
  },
  {
    name: "design",
  },
];
