import React from "react";

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">
        Jeff Sweeney
      </h1>
      <h3 className="home__subtitle"> Senior iOS Engineer</h3>
      <p className="home__description">
        email: jeffrey.sweeney812@gmail.com<br></br>
        www.linkedin.com/in/jeffsweeney812/<br></br>
        location: Odenton, MD
      </p>
      <div className="phone__img"></div>
    </div>
  );
};

export default Data;
